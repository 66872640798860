<template>
    <div class="px-2 px-lg-3 py-1 py-lg-2 border" style="background-color: cornsilk;" v-if="!readOnly && dsObjects.state._isLoaded" >
        <h5>{{$t("Handover Scope")}}</h5>
        <div class="text-muted">
            {{$t("Alter the scope by adding or removing related objects in the Workflow")}}
        </div>
        <ODataGrid
            :data-object="dsObjects"
            hide-action-column
            hideGridMenu
            hideMultiselectColumn
        >
            <OColumn :cellrendererparams="{href:'/objects/objectdetails?ID={{Object_ID}}', target:null }" field="Object" v-slot="{ row, column }">
                <OLink :modelValue="row" :column="column"></OLink>
            </OColumn>
            <OColumn field="ObjectDescription" width="250" :headerName="$t('Description')" :headerTitle="$t('Object Description')"/>
            <OColumn field="ObjectType" width="150" :headerName="$t('Type')" :headerTitle="$t('Object Type')" hide/>
            <OColumn field="TotalDocRequirements" width="100" :headerName="$t('# Req.')" :headerTitle="$t('Total amount of document requirements')" hide/>
            <OColumn field="VerifiedDocRequirements" width="100" :headerName="$t('# Verified Req.')" :headerTitle="$t('Amount of verified document requirements')" hide/>
            <OColumn field="MissingDocRequirements" width="150" :headerName="$t('Missing Doc. Req.')" :headerTitle="$t('Indicates whether documentation requirements is met or not for each object')"/>
            <OColumn field="DocLinks" width="100" :headerName="$t('# Doc Links')" :headerTitle="$t('Amount of document and object links that are not a requirement')" hide/>
            <OColumn field="MissingProperties" width="150" :headerName="$t('Missing Properties')" :headerTitle="$t('Indicates whether object property requirements is met or not for each object')"/>
            <OColumn field="IncludedInOtherHandoverWorkflows" width="150" :headerName="$t('Multiple Handovers')" :headerTitle="$t('Indicates wether the object is included in other handover workflows or not')"/>
            <OColumn field="IsTransferredToOperations" width="150" :headerName="$t('Is Handed over')" :headerTitle="$t('Indicates whether object is marked as transferred to operations or not')"/>
        </ODataGrid>
    </div>
</template>


<script setup>   
    import { ref, defineProps, popScopeId } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import utils from 'o365.modules.utils.js';
    import Properties from 'workflow.vue.components.Properties.vue';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean,
        objects:Object
    });
    
    const counter = ref(0);

    const dsObjects = getOrCreateDataObject({
        id: 'dsCompObjects',
        viewName: 'aviw_Assets_HandoverObjectsWF',
        maxRecords: -1,
        distinctRows: false,
        fields:
             [
                {name: "Object_ID", type: "int"},
                {name: "ID", type: "int"},
                {name: "Object", type: "string" },
                {name: "ObjectType", type: "string" },
                {name: "Name", type: "string" },
                {name: "ObjectDescription", type: "string" },
                {name: "ObjectType_ID", type: "string", sortOrder:1, sortDirection:"desc" },
                {name: "VerifiedDocRequirements", type: "int"},
                {name: "TotalDocRequirements", type: "int"},
                {name: "MissingDocRequirements", type: "bit"},
                {name: "DocLinks", type: "int"},
                {name: "MissingProperties", type:"bit"},
                {name: "IncludedInOtherHandoverWorkflows", type:"bit"},
                {name: "IsTransferredToOperations", type:"bit"}
             ]
    });

    if(!props.readOnly){
        dsObjects.recordSource.whereClause = "Item_ID =" + props.itemId;
        dsObjects.load();
    }
    
    props.objects.on("AfterSave",()=>{
        dsObjects.load();
    });

    props.objects.on("AfterDelete",()=>{
        dsObjects.load();
    });

</script>